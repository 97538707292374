import "./font-tracking";
import "./GlobalStyles.scss";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import "./TrustArcConsent";
import $ from "jquery";
import lazySizes from "lazysizes";
import { accordionAda } from "./AccordionAda/AccordionAda";
import { digitalDataContainer, dnbComLog, dnbScroll, extIcon, getGCLID, getVisitorID, globalContactCta, LogSeverity, setHiveNine } from "./FunctionsCore";
import { setFirstGlobals, setGlobals } from "./SetGlobals";
lazySizes.cfg.loadMode = 1;
// Z newRelicBrowserMonitoring
// Z setDigitalData
setFirstGlobals();
digitalDataContainer.registerCallback(() => {
    setHiveNine();
    getVisitorID();
    getGCLID();
    $(() => {
        const $scrollToCta = $(".scroll-to-cta");
        $scrollToCta.on("click", function (cl) {
            cl.preventDefault();
            const $this = $(this);
            // If there is a floating/global lead form, open it, otherwise scroll to the non-floating/global lead form
            if (globalContactCta.exists()) {
                const ariaExpanded = $this.attr("aria-expanded");
                const ariaExpandedNegated = ariaExpanded === "true" ? "false" : "true";
                $this.attr("aria-expanded", ariaExpandedNegated);
                window.setTimeout(() => $this.attr("aria-expanded", "false"), 2000);
                globalContactCta.togglePanel();
            }
            else if ($(".contact_cta").length > 0) {
                // This may be unused
                // Scroll to form in contact_cta
                dnbScroll(".contact_cta", void 0);
            }
            else {
                dnbComLog(LogSeverity.ERROR, 'Could not determine what action to take on click of element with class "scroll-to-cta"');
            }
        });
        $(".button .add")
            .not(".clicked")
            .on("click", function () {
            const $this = $(this);
            $this.addClass("clicked");
            $this.siblings("label").html("topic added");
        });
        const $a = $("a");
        $a.filter((_, element) => element instanceof HTMLAnchorElement && element.hostname.match("hoovers.com") === null && element.href.match(/.pdf$/) === null && element.href.match(/^javascript:/) === null && element.href.match(/^mailto:/) === null && element.hostname !== window.location.hostname && element.href.length > 0).attr("target", "_blank");
        $a.each(function () {
            const $this = $(this);
            if ($this.attr("target") === "_blank") {
                $this.addClass(extIcon);
            }
        });
        $('a[href^="tel"]').removeClass(extIcon);
        const $extensionIcon = $(`.${extIcon}`);
        $extensionIcon.find(".button").parent().addClass("card-icon");
        $extensionIcon.find(".button-trailing").filter(`.${extIcon}`).parent().addClass("card-icon");
        $extensionIcon.find(".form-inline").parent().addClass("card-icon");
        $("button.button.cta").parents(`.${extIcon}`).addClass("button-icon");
        $extensionIcon.filter(".card-icon").find(".hidden-accessible").remove();
        $extensionIcon.filter(".button-trailing").append('<span class="hidden-accessible">External Website. Opens New Window</span>');
        $("footer .link-category .title a").on("click", function (cl) {
            cl.preventDefault();
            const $parents = $(this).parents(".link-category");
            $parents.siblings().removeClass("open");
            $parents.toggleClass("open");
        });
    });
    accordionAda();
    setGlobals(false);
});
