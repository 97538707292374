"use strict";
/* eslint-disable */
// @ts-nocheck
// Font tracking required by fonts.com
var MTIProjectId = "6ab9fbbd-5798-4d20-9aad-4e42920fcb9f";
(function () {
    var mtiTracking = document.createElement("script");
    mtiTracking.type = "text/plain";
    mtiTracking.className = "trustecm";
    mtiTracking.setAttribute("trackertype", "advertising");
    mtiTracking.async = "true";
    mtiTracking.dataset.src = "/apps/dnb/servlets/VersionedStaticResourcesServlet?t=1658465035384&s=mtiFontTrackingCode.js";
    (document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]).appendChild(mtiTracking);
})();
