import $ from "jquery";
import Cookies from "js-cookie";
import AccessibilityWarningStyles from "../AccessibilityWarningStyles.module.scss";
import { digitalDataContainer, isNotUndefinedOrNull, nonAaAxeViolationsIdSet, setRuntimeEnvironment, windowOnLoadOrImmediate } from "../FunctionsCore";
const styles = AccessibilityWarningStyles;
const setDigitalData = (setDigitalDataParameter) => {
    const { digitalData, runtimeEnvironment } = setDigitalDataParameter;
    if (isNotUndefinedOrNull(runtimeEnvironment)) {
        setRuntimeEnvironment(runtimeEnvironment);
        windowOnLoadOrImmediate($(window), () => {
            window.setTimeout(() => {
                import("axe-core").then(ax => {
                    ax
                        .run()
                        .then(axe => {
                        const aaViolations = axe.violations.filter(axeR => !nonAaAxeViolationsIdSet.has(axeR.id));
                        const aaViolationsLength = aaViolations.length;
                        for (const axeR of aaViolations) {
                            window.console.error(`Accessibility violation - ${axeR.impact} - ${axeR.description}`);
                            for (const no of axeR.nodes) {
                                window.console.error("target", no.target);
                                window.console.error("HTML", no.html);
                                for (const st of no.target) {
                                    $(st)
                                        .addClass(styles.accessibilityViolation)
                                        .attr("data-accessibility-violation-help-url", axeR.helpUrl)
                                        .attr("data-accessibility-violation-id", axeR.id);
                                }
                            }
                        }
                        if (aaViolationsLength > 0) {
                            const $div = $("<div></div>")
                                .addClass(styles.leftWarning)
                                .text(`${aaViolationsLength} WCAG AA accessibility violations detected, check the console`);
                            const removeDiv = () => $div.remove();
                            const $button = $("<button></button>")
                                .addClass(styles.closeButton)
                                .attr("type", "button")
                                .on("click", () => removeDiv())
                                .text("Close");
                            const $buttonTwo = $("<button></button>")
                                .addClass(styles.closeButton)
                                .attr("type", "button")
                                .on("click", () => {
                                removeDiv();
                                Cookies.set("hideAccessibilityWarnings", "true", {
                                    // One hour from now
                                    "expires": new Date(Date.now() + (60 * 60 * 1000)),
                                    "path": "/"
                                });
                            })
                                .text("Close and hide warnings for an hour");
                            const $buttonWrapper = $("<div></div>").addClass(styles.buttonWrapper);
                            $buttonWrapper.append($button).append($buttonTwo);
                            $div.append($buttonWrapper);
                            $("body").append($div);
                        }
                    })
                        .catch(er => {
                        throw new Error(er);
                    });
                });
            }, 1000);
        });
    }
    // Expose globally
    window["digitalData"] = digitalData;
    digitalDataContainer.setDigitalData(digitalData);
};
export { setDigitalData };
