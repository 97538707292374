import $ from "jquery";
import keycode from "keycode";
import { isNotUndefined } from "../FunctionsCore";
const accordionAda = () => {
    import("./AccordionAdaStylesModule").then(() => {
        // W3.org accordion JS
        /*
         *   This content is licensed according to the W3C Software License at
         *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
         *
         *   Simple accordion pattern example
         */
        const accordionTriggerClassName = "Accordion-trigger";
        const ariaControlsAttributeName = "aria-controls";
        const ariaExpandedAttributeName = "aria-expanded";
        const focusClassName = "focus";
        const hiddenAttributeName = "hidden";
        $(() => {
            // Elements with this class come from content (links in site footer), not from the codebase
            $(".Accordion").each(function () {
                const $this = $(this);
                // Allow for multiple accordion sections to be expanded at the same time
                const allowMultiple = isNotUndefined($this.attr("data-allow-multiple"));
                // Allow for each toggle to both open and close individually
                const allowToggle = allowMultiple || isNotUndefined($this.attr("data-allow-toggle"));
                const $accordionTrigger = $this.find(`.${accordionTriggerClassName}`);
                $accordionTrigger.each(function () {
                    const $thisTwo = $(this);
                    $thisTwo.on("click", (cl) => {
                        // Check if the current toggle is expanded.
                        const isExpanded = $thisTwo.attr(ariaExpandedAttributeName) === "true";
                        const $ariaExpandedTrue = $thisTwo.find(`[${ariaExpandedAttributeName}="true"]`);
                        // Without allowMultiple, close the open accordion
                        if (!allowMultiple && $ariaExpandedTrue.length > 0 && $ariaExpandedTrue[0] !== $thisTwo[0]) {
                            // Set the expanded state on the triggering element
                            $ariaExpandedTrue.attr(ariaExpandedAttributeName, "false");
                            // Hide the accordion sections, using aria-controls to specify the desired section
                            $(`#${$ariaExpandedTrue.attr(ariaControlsAttributeName)}`).attr(hiddenAttributeName, "");
                        }
                        const $ariaControlsAttributeNameValue = $(`#${$thisTwo.attr(ariaControlsAttributeName)}`);
                        if (!isExpanded) {
                            // Set the expanded state on the triggering element
                            $thisTwo.attr(ariaExpandedAttributeName, "true");
                            // Hide the accordion sections, using aria-controls to specify the desired section
                            $ariaControlsAttributeNameValue.removeAttr(hiddenAttributeName);
                        }
                        else if (allowToggle) {
                            // Set the expanded state on the triggering element
                            $thisTwo.attr(ariaExpandedAttributeName, "false");
                            // Hide the accordion sections, using aria-controls to specify the desired section
                            $ariaControlsAttributeNameValue.attr(hiddenAttributeName, "");
                        }
                        cl.preventDefault();
                    });
                });
                // Bind keyboard behaviors on the main accordion container
                $this.on("keydown", (ke) => {
                    const target = ke.target;
                    const which = ke.which;
                    const isDown = which === keycode.codes.down;
                    const isPageDown = which === keycode.codes["page down"];
                    const ctrlModifier = ke.ctrlKey && (which === keycode.codes["page up"] || isPageDown);
                    // Up/down arrow and control + page up/page down keyboard operations
                    if (which === keycode.codes.up || isDown || ctrlModifier) {
                        const isDownOrPageDown = isDown || isPageDown;
                        const direction = isDownOrPageDown ? 1 : -1;
                        const index = $accordionTrigger.index(target);
                        const accordionTriggerLength = $accordionTrigger.length;
                        const newIndex = (index + accordionTriggerLength + direction) % accordionTriggerLength;
                        $accordionTrigger[newIndex].focus();
                        ke.preventDefault();
                    }
                    else if (which === keycode.codes.home) {
                        // Go to first accordion
                        $accordionTrigger[0].focus();
                    }
                    else if (which === keycode.codes.end) {
                        // Go to last accordion
                        $accordionTrigger.slice(-1)[0].focus();
                    }
                });
                // These are used to style the accordion when one of the buttons has focus
                $accordionTrigger
                    .on("blur", () => $this.removeClass(focusClassName))
                    .on("focus", () => $this.removeClass(focusClassName));
            });
        });
    });
};
export { accordionAda };
