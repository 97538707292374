/* eslint-disable */
// @ts-nocheck
import { isUndefined } from "../FunctionsCore";
const newRelicBrowserMonitoring = (newRelicBrowserMonitoringParameter) => {
    const nreum = "NREUM";
    const windowNreum = window[nreum];
    const setWindowNreum = () => {
        const newWindowNreum = {};
        window[nreum] = newWindowNreum;
        return newWindowNreum;
    };
    const windowNreumToUse = isUndefined(windowNreum)
        ? setWindowNreum()
        : windowNreum;
    windowNreumToUse["init"] =
        {
            "ajax": {
                "deny_list": ["bam.nr-data.net"]
            },
            "distributed_tracing": {
                "enabled": true
            },
            "privacy": {
                "cookies_enabled": true
            },
        };
    const htmlScriptElement = window.document.createElement("script");
    htmlScriptElement.type = "text/plain";
    htmlScriptElement.className = "trustecm";
    htmlScriptElement.setAttribute("trackertype", "advertising");
    htmlScriptElement.dataset.src = newRelicBrowserMonitoringParameter.loaderEndpoint;
    window.document.head.append(htmlScriptElement);
    htmlScriptElement.addEventListener("load", () => {
        windowNreumToUse["loader_config"] =
            {
                "accountID": newRelicBrowserMonitoringParameter.accountId,
                "agentID": newRelicBrowserMonitoringParameter.applicationId,
                "applicationID": newRelicBrowserMonitoringParameter.applicationId,
                "licenseKey": newRelicBrowserMonitoringParameter.licenseKey,
                "trustKey": newRelicBrowserMonitoringParameter.accountId,
            };
        windowNreumToUse["info"] =
            {
                "applicationID": newRelicBrowserMonitoringParameter.applicationId,
                "beacon": "bam.nr-data.net",
                "errorBeacon": "bam.nr-data.net",
                "licenseKey": newRelicBrowserMonitoringParameter.licenseKey,
                "sa": 1,
            };
        // Extra data
        const windowNewrelic = window["newrelic"];
        const windowNewrelicSetCustomAttribute = windowNewrelic["setCustomAttribute"];
        windowNewrelicSetCustomAttribute("env", newRelicBrowserMonitoringParameter.env);
        windowNewrelicSetCustomAttribute("environmentRunMode", newRelicBrowserMonitoringParameter.environmentRunMode);
        windowNewrelicSetCustomAttribute("serviceRunMode", newRelicBrowserMonitoringParameter.serviceRunMode);
        windowNewrelicSetCustomAttribute("slingRunModes", newRelicBrowserMonitoringParameter.slingRunModes);
        windowNewrelic["addRelease"]("dnb.com", newRelicBrowserMonitoringParameter.gitCommitId);
    });
};
export { newRelicBrowserMonitoring };
