import "jquery-migrate";
import $ from "jquery";
import { dnbComLog, isNotUndefined, isUndefined, LogSeverity } from "./FunctionsCore";
import { firstGlobals, immediateFunctions, nonImmediateFunctions } from "./GlobalExports";
const firstGlobalsTyped = firstGlobals;
const immediateFunctionsTyped = immediateFunctions;
const nonImmediateFunctionsTyped = nonImmediateFunctions;
const set = new window.Set();
const dmkAemSixFiveObject = {};
const dmkAemSixFive = "___DMK_AEM_SIX_FIVE___";
// @ts-expect-error Ignore
window[dmkAemSixFive] = dmkAemSixFiveObject;
const setOnNamespace = (key, value) => {
    if (set.has(key)) {
        dnbComLog(LogSeverity.ERROR, "Duplicate key found in namespace", key);
        throw new Error(`Duplicate key found in namespace (key: ${key})`);
    }
    else {
        set.add(key);
        dmkAemSixFiveObject[key] = value;
        // TODO
        // Temporary, for compatibility. Remove after April 2023.
        // @ts-expect-error Ignore
        window[key] = value;
    }
};
const setFirstGlobals = () => {
    for (const im of firstGlobalsTyped) {
        setOnNamespace(im.functionName, im.functionToCall);
    }
};
const setGlobals = (fromPure) => {
    let domContentLoadedFired = false;
    let pendingExecutions = void 0;
    const show = () => $(".bodyClass").removeClass("hideBodyClass");
    if (!fromPure) {
        $(() => {
            domContentLoadedFired = true;
            if (pendingExecutions === 0) {
                dnbComLog(LogSeverity.WARN, "domContentLoadedFired just fired but pendingExecutions is already 0: ", new window.Date());
                show();
            }
            if (isUndefined(pendingExecutions)) {
                dnbComLog(LogSeverity.INFO, "domContentLoadedFired fired but pendingExecutions was never initialized: ", new window.Date());
                show();
            }
        });
    }
    const incrementPendingExecutions = () => {
        if (!fromPure) {
            if (isUndefined(pendingExecutions)) {
                dnbComLog(LogSeverity.DEBUG, "Initializing pendingExecutions: ", new window.Date());
                pendingExecutions = 1;
            }
            else {
                pendingExecutions += 1;
            }
        }
    };
    const decrementPendingExecutions = () => {
        if (!fromPure) {
            if (isUndefined(pendingExecutions)) {
                throw new Error("pendingExecutions has not been initialized");
            }
            if (pendingExecutions < 1) {
                throw new Error("pendingExecutions is already less than 1");
            }
            pendingExecutions -= 1;
            dnbComLog(LogSeverity.DEBUG, "pendingExecutions is now: ", pendingExecutions);
            if (domContentLoadedFired && pendingExecutions === 0) {
                dnbComLog(LogSeverity.DEBUG, "domContentLoadedFired is true and pendingExecutions is 0: ", new window.Date());
                show();
            }
        }
    };
    const createNonImmediateFunction = (functionKey, getPromise, runOnce) => {
        const runOnceIsTrue = runOnce === true;
        let hasRun = false;
        let savedFunction = void 0;
        return (...originalArguments) => {
            if (runOnceIsTrue && hasRun) {
                return;
            }
            const firstArgument = originalArguments[0];
            // TODO
            // Temporary, for compatibility. Remove after April 2023.
            // Support "function(f){f({"
            const isFunction = typeof firstArgument === "function";
            const runFunction = (functionToRun) => {
                try {
                    if (runOnceIsTrue) {
                        hasRun = true;
                    }
                    if (isFunction) {
                        firstArgument(functionToRun);
                    }
                    else {
                        functionToRun(...originalArguments);
                    }
                }
                catch (er) {
                    dnbComLog(LogSeverity.ERROR, er);
                    throw er;
                }
            };
            if (isNotUndefined(savedFunction)) {
                runFunction(savedFunction);
            }
            else {
                incrementPendingExecutions();
                getPromise().then((an) => {
                    decrementPendingExecutions();
                    const value = an[functionKey];
                    const valueAny = value;
                    savedFunction = valueAny;
                    runFunction(valueAny);
                });
            }
        };
    };
    for (const im of immediateFunctionsTyped) {
        setOnNamespace(im.functionName, im.functionToCall);
    }
    for (const currentValue of nonImmediateFunctionsTyped) {
        const functionKey = currentValue.functionKey;
        if (typeof functionKey !== "string") {
            throw new Error();
        }
        const nonImmediateFunction = createNonImmediateFunction(functionKey, currentValue.getPromise, currentValue.runOnce);
        setOnNamespace(functionKey, nonImmediateFunction);
    }
    window["$"] = $;
    window["jQuery"] = $;
};
export { dmkAemSixFive, setFirstGlobals, setGlobals, setOnNamespace };
